.text_headline1{
    font-weight: 800;
    font-size: 56px;
    line-height: 64px;
    color: $black;
  }
  
  .text_headline2{
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    color: $black;
  }
  
  .text_headline3{
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    color: $black;
  }
  
  .text_title_big{
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: $black;
  }
  
  .text_title{
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: $black;
  }
  
  .text_subtitle_big{
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: $black;
  }
  
  .text_subtitle{
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $black;
  }
  
  .text_subtitle_semi_bold{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $black;
  }
  .text_subtitle_medium{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $black;
  }
  .text_subtitle_charcoal_semi_bold{
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: $charcoalGrey
  }
  
  .text_subtitle_light{
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $dark-grey;
  }
  
  .text_subtitle_light_grey_semi_bold{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $light-grey;
  }
  
  .text_body_big{
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: $black;
  }
  .text_body_big_bold{
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: $black;
  }
  
  .text_body_big_light{
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: $dark-grey;
  }
  
  .text_body_big_light_semi_bold{
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: $dark-grey;
  }
  
  
  .text_body_big_light_bold{
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: $dark-grey;
  }
  
  .text_body{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $black;
  }
  
  .text_body_bold{
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $black;
  }
  
  .text_body_light{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $dark-grey;
  }
  
  .text_hint{
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $black;
  }
  
  .text_hint_light{
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $dark-grey;
  }
  
  .text_caption_bold{
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: $black;
  }
  
  .text_caption{
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $dark-grey;
  }
  
  .text_caption_light{
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $light-grey;
  }
  
  .text_caption_error{
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $red;
  }
  
  .text_caption_small_light{
    font-size: 10px;
    font-weight: 700;
    line-height: 14px;
    color: $light-grey;
  }
  
  .text_tag{
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: $light-grey;
  }
  
  .text_link_big{
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: $turquoise;
    cursor: pointer;
  }
  
  .text_link{
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $turquoise;
    cursor: pointer;
  }
  
  .text_link_semi_bold{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $turquoise;
    cursor: pointer;
  }
  
  .text_link_small{
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: $turquoise;
    cursor: pointer;
  }
  
  .hover_under_line{
    text-decoration: none;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .text_label{
    font-weight: 600;
    font-size: 14px;
    color: #282A2C
  }
  